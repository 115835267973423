.background-image{
    width: 56%;
    top: -25%;
    min-height: 36%;
    position: absolute;
    z-index: 1;
}

.logo-image{
    position: relative;
    width: 450px;
    margin-top: 8%;
    z-index: 2;
}