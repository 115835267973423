.card-list-item {
  width: 200px;
  height: 250px;
  cursor: pointer;
  text-align: center;
  box-shadow: -5px 5px 20px rgb(199, 199, 199);
  border-radius: 16px;
  border: 1px solid rgba(172, 171, 171, 0.25);
  background-color: white;
  overflow: hidden;
}

.loading-container {
  max-width: 18%;
  min-width: 280px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-list-item p {
  margin: 0 auto;
}

.create-body img {
  margin-top: 0px;
}

.create-body p {
  font-size: large;
  font-weight: 600;
}

.employee-name-text {
  font-size: 18px;
}
.employee-info-text {
  font-size: 14px;
}

.card-list-item .info-container {
  margin: 16px 0px;
}

.card-list-item .employee-image {
  margin-top: 12px;
  border-radius: 16px;
  width: 40%;
  min-height: 110px;
  max-height: 35%;
}

.card-highlight {
  width: 100%;
  background-color: #0061f7;
}

.card-highlight-company {
  color: white;
  padding-top: 8px;
  font-size: 20px;
}

.card-highlight-name {
  color: white;
  padding-bottom: 12px;
  font-size: 20px;
}

.selectable-card {
  cursor: pointer;
}

form {
  width: 100%;
}

.card-form {
  max-width: 40%;
  min-width: 400px;
}

.input-error-message {
  color: #F26464;
  font-weight: 600;
  font-size: 0.85rem;
  padding-left: 0.5rem;
  margin: 0;
}

.border-bottom-input {
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
  border-radius: 0%;
}

.image-input-container {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.profile-image {
  margin-bottom: 12px;
  border: 1px solid #444;
}

.rounded-profile-image {
  margin-bottom: 12px;
  border: 1px solid #444;
  border-radius: 50%;
}

.file-input {
  width: 100%;
}

.simple-input-container {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-subtitle {
  font-family: Lato;
  font-weight: 700;
  font-size: 14px;
  font-style: italic;
  margin-top: 12px;
}
.multiple-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.url-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-icon {
  border-radius: 8px;
  background-color: black;
  margin: auto;
  height: 40px;
  width: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-field-container {
  width: 20px;
  height: 20px;
  color: #444;
  display: inline-block;
  margin-left: 12px;
  cursor: pointer;
}

.template {
  text-align: center;

  width: 350px;
  min-height: 650px;
  padding-bottom: 16px;
}

.template-selection-area {
  text-align: center;
  width: 356px;
  height: 656px;
  border-radius: 10px;
  border: none;
  margin: 0px auto;
}

.photo {
  border: 2px solid rgb(231, 231, 231);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.template-one .photo {
  background-color: rgb(167, 167, 167);
  width: 50%;
  height: 180px;
  left: 0px;
  top: 0px;
  margin: 30px 15px;
  position: relative;
  z-index: 2;
}

.template-one .photo-area {
  position: relative;
  margin: 0px;
  width: 100%;
  height: 35%;
}

.partial-background {
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 75%;
  background-color: rgb(47, 47, 47);
}

.template-two .photo {
  background-color: rgb(167, 167, 167);
  border-radius: 50%;
  width: 50%;
  height: 180px;
  left: 0px;
  top: 0px;
  margin: 30px 15px;
  position: relative;
  z-index: 2;
}

.template-two .photo-area {
  position: relative;
  margin: 0px;
  width: 100%;
  height: 35%;
}

.template-main-content {
  margin: 0px auto;
}

.name-card-text {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.job-card-text {
  margin: 0;
  font-size: 18px;
}

.company-card-text {
  margin: 0;
  font-size: 16px;
}

.template-introduction {
  width: 75%;
  margin: 0px auto;
}

.intro-card-text {
  margin: 0;
  font-size: 12px;
  margin: 8px 0px;
}

.template-one .template-contacts {
  width: 50%;
  margin: 0px auto;
}

.template-two .template-contacts {
  width: 75%;
  margin: 0px auto;
}

.simple-contact-option {
  background-color: rgb(47, 47, 47);
  width: 100%;
  color: #fff;
  margin: 8px 0px;
  padding: 4px 0px;
  font-size: 14px;
}

.text-contact-option {
  font-size: 14px;
  margin-bottom: 0px;
}

.social-square-icon {
  background-color: rgb(47, 47, 47);
  padding: 0 !important;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  line-height: 30px;
  margin: 4px 3%;
}

.template-social-networks {
  width: 45%;
  margin: 0px auto;
}

.add-contact-btn {
  margin: 16px 0px;
  padding: 8px 16px;
  border: 1px solid rgb(47, 47, 47);
  background-color: #fff;
  font-size: 14px;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 100px 20vw;
  color: #fff;
}

.blue {
  background-color: #0061f7;
}

.black {
  background-color: rgb(39, 38, 38);
}

.curve {
  position: absolute;
  height: 225px;
  width: 100%;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  height: 100%;
  background-color: rgb(39, 38, 38);
  transform: translate(85%, 60%);
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  height: 100%;
  background-color: #0061f7;
  transform: translate(-4%, 40%);
  z-index: -1;
}

.custom-shape-divider-top-1648103944 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-black {
  position: absolute;
  margin-top: 187px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-black svg {
  position: relative;
  display: block;
  width: calc(139% + 1.3px);
  height: 48px;
}

.custom-shape-divider-bottom-black .shape-fill {
  fill: #2b2b2b;
}

.custom-shape-divider-bottom-white {
  position: absolute;
  margin-top: 197px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

@media (min-width: 1920px) {
  .custom-shape-divider-bottom-black {
    margin-top: 212px;
  }
  .custom-shape-divider-bottom-white {
    margin-top: 219px;
  }
}

@media (max-width: 425px) {
  .url-container {
    flex-wrap: wrap;
  }
  .url-container > p {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .template-introduction {
    width: auto;
  }
  .custom-shape-divider-bottom-black {
    margin-top: 154px;
  }
  .custom-shape-divider-bottom-white {
    margin-top: 161px;
  }
}

.custom-shape-divider-bottom-white svg {
  position: relative;
  display: block;
  width: calc(139% + 1.3px);
  height: 46px;
}

.custom-shape-divider-bottom-white .shape-fill {
  fill: #ffffff;
}

@media (min-width: 1920px) {
  .card-list-item {
    width: 210px;
  }
}
