.App {
}

.screen-margin {
  margin: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0px;
}

.social-networks__link {
  font-size: 20px;
}

.card-field-list .whatsapp-business-icon,
.card-field-list .linkedin-business-icon {
  width: 50%;
}

.template-two .whatsapp-business-icon,
.template-two .linkedin-business-icon {
  width: 60%;
}

.whatsapp-business-icon {
  background: url("./common/assets/img/Whatsapp-Business-Logo-White.png");
  width: 75%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  display: block;
  margin: 0 auto;
}

.linkedin-business-icon {
  background: url("./common/assets/img/linkedin-business.png");
  width: 65%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  display: block;
  margin: 0 auto;
}
